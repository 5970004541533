<template>
  <div class="w-full m-auto lg:w-9">
    <div v-if="true" class="col-12 text-700 text-center">
      <div class="text-900 font-bold text-5xl mb-5">Your Headshots</div>
    </div>
    <div class="surface-section px-4 py-8 md:px-6 lg:px-8 col-12">
      <div  v-if="isLoadingPayment"
        class="
          flex
          lg:align-items-start
          flex-column
          lg:flex-row
          p-4
          bg-blue-100
          border-round border-1 border-blue-300
        "
      >
        <div class="flex align-items-start">
          <i class="pi pi-check-circle text-blue-900 text-2xl mr-3"></i>
          <div class="mr-3 flex lg:align-items-start flex-column lg:flex-row">
            <p class="m-0 p-0 text-blue-700 line-height-3">
               Your images are being generated now. This may take a few minutes. Wait here or check back later.
            </p>
          </div>
        </div>
      </div>
      <div v-if="isLoading" class="my-5">
        <center><ProgressSpinner /></center>
      </div>
      <div v-if="!isLoading && !showPaymentError" class="grid">
        <div class="col-12">
          <div class="grid">
            <div class="col-12 lg:col-8">
              <div class="">
                <!-- <h5>Image</h5> -->
                <div class="flex justify-content-center">
                  <Image
                    :src="
                      basicImages.length
                        ? basicImages[0].url
                        : 'https://via.placeholder.com/1000x600'
                    "
                    alt="Image"
                    imageStyle="width:100%;"
                    preview
                  />
                </div>
              </div>
            </div>
            <div class="col-12 lg:col-4">
              <div class="flex justify-content-center">
                <Button
                  @click="download"
                  :icon="'pi ' + (isDemo ? 'pi-lock' : 'pi-download')"
                  label="Download Images"
                  class="p-3 lg:px-5 m-4 mt-7 text-xl"
                ></Button>
              </div>
            </div>
            <div class="col-12">
              <h3 class="my-5"><center>{{isDemo ? "Basic Backgrounds" : "All" }}</center></h3>
              <div class="grid">
                <div
                  v-for="image in basicImages"
                  :key="image.url"
                  class="col-12 lg:col-4 p-2"
                >
                  <div class="">
                    <!-- <h5>Image</h5> -->
                    <div class="flex justify-content-center">
                      <Image :src="image.url" alt="Image" width="220" preview />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="proImages.length > 0" class="col-12">
              <h3 class="my-5"><center>Pro Backgrounds</center></h3>
              <div class="grid">
                <div
                  v-for="image in proImages"
                  :key="image.url"
                  class="col-12 lg:col-4 p-2"
                >
                  <div class="">
                    <!-- <h5>Image</h5> -->
                    <div class="flex justify-content-center">
                      <Image :src="image.url" alt="Image" width="220" preview />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12">
              <h3 class="my-5"><center>Profile Pics</center></h3>
              <div class="grid">
                <div
                  v-for="url in croppedImages"
                  :key="url"
                  class="col-12 lg:col-4 p-2"
                >
                  <div class="">
                    <!-- <h5>Image</h5> -->
                    <div class="flex justify-content-center">
                      <Image :src="url" alt="Image" width="150" preview />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Dialog
      header="Choose a package"
      :visible="showPaymentModal"
      :style="{ width: '50vw' }"
      :breakpoints="{ '960px': '90vw' }"
      :modal="true"
    >
      <div class="grid">
        <div class="col-12 lg:col-6">
          <div class="p-3 h-full">
            <div
              class="shadow-2 p-3 h-full flex flex-column surface-card"
              style="border-radius: 6px"
            >
              <div class="text-900 font-medium text-xl mb-2">Basic</div>
              <div class="text-600">Buy this headshot</div>
              <hr class="my-3 mx-0 border-top-1 border-none surface-border" />
              <div class="flex align-items-center">
                <span class="font-bold text-2xl text-900">$25</span>
                <!-- <span class="ml-2 font-medium text-600">One-time purchase</span> -->
              </div>
              <hr class="my-3 mx-0 border-top-1 border-none surface-border" />
              <ul class="list-none p-0 m-0 flex-grow-1">
                <li class="flex align-items-center mb-3">
                  <i class="pi pi-check-circle text-green-500 mr-2"></i>
                  <span>Get this image with all basic backgrounds</span>
                </li>
                <li class="flex align-items-center mb-3">
                  <i class="pi pi-check-circle text-green-500 mr-2"></i>
                  <span>10 different backgrounds</span>
                </li>
                <li class="flex align-items-center mb-3">
                  <i class="pi pi-check-circle text-green-500 mr-2"></i>
                  <span>10 pro-cropped profile pictures</span>
                </li>
                <!-- <li class="flex align-items-center mb-3">
                  <i class="pi pi-check-circle text-green-500 mr-2"></i>
                  <span>15 high quality files total</span>
                </li> -->
              </ul>
              <hr class="mb-3 mx-0 border-top-1 border-none surface-border" />
              <Button
                @click="openStripeCheckout('basic')"
                label="Buy Now"
                class="p-3 w-full"
              ></Button>
            </div>
          </div>
        </div>

        <div class="col-12 lg:col-6">
          <div class="p-3 h-full">
            <div
              class="shadow-2 p-3 flex flex-column surface-card"
              style="border-radius: 6px"
            >
              <div class="text-900 font-medium text-xl mb-2">Pro</div>
              <div class="text-600">Get this headshot and more</div>
              <hr class="my-3 mx-0 border-top-1 border-none surface-border" />
              <div class="flex align-items-center">
                <span class="font-bold text-2xl text-900">$40</span>
                <!-- <span class="ml-2 font-medium text-600">per month</span> -->
              </div>
              <hr class="my-3 mx-0 border-top-1 border-none surface-border" />
              <ul class="list-none p-0 m-0 flex-grow-1">
                <li class="flex align-items-center mb-3">
                  <i class="pi pi-check-circle text-green-500 mr-2"></i>
                  <span>Get this image with all basic and pro backgrounds</span>
                </li>
                <li class="flex align-items-center mb-3">
                  <i class="pi pi-check-circle text-green-500 mr-2"></i>
                  <span>Get raw transparent files</span>
                </li>
                <li class="flex align-items-center mb-3">
                  <i class="pi pi-check-circle text-green-500 mr-2"></i>
                  <span>Access to over 20 professional backgrounds</span>
                </li>
                <!-- <li class="flex align-items-center mb-3">
                  <i class="pi pi-check-circle text-green-500 mr-2"></i>
                  <span>Upload custom background</span>
                </li> -->
                <!-- <li class="flex align-items-center mb-3">
                  <i class="pi pi-check-circle text-green-500 mr-2"></i>
                  <span>Get 5 free headshots a month</span>
                </li> -->
                <!-- <li class="flex align-items-center mb-3">
                  <i class="pi pi-check-circle text-green-500 mr-2"></i>
                  <span>Cancel anytime</span>
                </li> -->
              </ul>
              <hr class="mb-3 mx-0 border-top-1 border-none surface-border" />
              <Button
                @click="openStripeCheckout('pro')"
                label="Buy Now"
                class="p-3 w-full p-button-outlined"
              ></Button>
            </div>
          </div>
        </div>
      </div>
      <!-- <template #footer>
        <Button
          label="No"
          icon="pi pi-times"
          @click="closeModal"
          class="p-button-text"
        />
        <Button label="Yes" icon="pi pi-check" @click="closeModal" autofocus />
      </template> -->
    </Dialog>
  </div>
</template>

<script>
import * as axios from "axios";
import JSZip from "jszip";
import { saveAs } from "file-saver";

export default {
  props: ["demoId", "gallery"],
  data() {
    return {
      basicImages: [],
      proImages: [],
      croppedImages: [],
      showPaymentModal: false,
      isLoading: true,
      isLoadingPayment: false,
      showPaymentError: false,
    };
  },
  async mounted() {
    for (let i = 0; i < 10; i++) {
      //this.urls.push("https://via.placeholder.com/300x200");
    }
    console.log(this.demoId, this.gallery);
    var url = this.isDemo ? "/api/get-demo" : "/api/get-gallery";
    var response = await axios.post(url, { demoId: this.demoId });

    if (response.data.error) {
      console.log("error", response.data.error);
      return;
    }

    if (response.data.redirect === true) {
      var success = this.$route.query.success;
      if (success === "true") {
        var count = 0;
        var poll = async () => {
          var response = await axios.post("/api/get-gallery", {
            demoId: this.demoId,
          });
          if (response.data.error) {
            console.log("error", response.data.error);
            return;
          }
          if (response.data.redirect === true) {
            count++;
            if (count < 30) {
              setTimeout(poll, 1000);
            } else {
              this.isLoadingPayment = false;
              this.showPaymentError = true;
              this.isLoading = false;
            }
            return;
          }
          if (response.data.images.length > 0) {
            this.basicImages = response.data.images;
            this.croppedImages = response.data.cropped;
            if(response.data.pro) {
              this.proImages = response.data.pro;
            }
            this.isLoadingPayment = false;
            this.isLoading = false;
            return;
          }
        };
        this.isLoadingPayment = true;
        poll();
        return;
      } else {
        this.$router.push({ name: "demo-gallery" });
      }
      return;
    }

    this.basicImages = response.data.images;
    this.croppedImages = response.data.cropped;
    if(response.data.pro) {
              this.proImages = response.data.pro;
            }

    this.basicImages.sort((a, b) => {
      return (a.background.order || 100) - (b.background.order || 100);
    });
    this.proImages.sort((a, b) => {
      return (a.background.order || 100) - (b.background.order || 100);
    });

    this.isLoading = false;
  },
  computed: {
    isDemo() {
      return this.gallery != true;
    },
  },
  methods: {
    download() {
      if (this.isDemo) {
        this.showPaymentModal = true;
        return;
      } else {
        this.zip();
      }
    },
    closeModal() {
      this.showPaymentModal = false;
    },
    openModal() {
      this.showPaymentModal = true;
    },
    async openStripeCheckout(price) {
      if(window.location.host === "app.hyperheadshots.com") {
        window.location.href = `https://api.hyperheadshots.com/api/stripe/checkout?price=${price}&demoId=${this.demoId}`;
      } else {
        window.location.href = `/api/stripe/checkout?price=${price}&demoId=${this.demoId}`;
      }
      
    },
    zip() {
      this.isLoading = true;
      var zip = new JSZip();
      var folder = zip.folder("headshots");
      this.basicImages.forEach((image, index) => {
        //console.log(image.url);
        folder.file(`${index}.jpg`, this.downloadImage(image.url), {
          base64: true,
        });
      });
      this.proImages.forEach((image, index) => {
        //console.log(image.url);
        folder.file(`${index}.jpg`, this.downloadImage(image.url), {
          base64: true,
        });
      });
      zip.generateAsync({ type: "blob" }).then((content) => {
        saveAs(content, "headshots.zip");
        this.isLoading = false;
      });
    },
    // a function that downloads an image and returns its binary data
    downloadImage(url) {
      return axios
        .get(url, { responseType: "arraybuffer" })
        .then((response) => {
          return response.data;
        });
    },
  },
};
</script>
